import { Room } from 'matrix-js-sdk';
import { useMatrixRoomMessages } from './useMatrixRoomMessages';
import { AnnouncementsType } from '../../components/AnnouncementsList/AnnouncementsList';
import { useRefreshOnEvent } from './useRefreshOnEvent';
import { lcFirst } from '../../utils/lcFirst';
import { NotificationReasons } from '../../utils/matrixClient';

export type MatrixAnnouncementContent = {
  type: NotificationReasons;
  title: string;
  content: string;
};

export const useGetAnnouncements = (room?: Room): AnnouncementsType[] => {
  const messages = useMatrixRoomMessages(room?.roomId ?? '');
  useRefreshOnEvent();

  const announcements: AnnouncementsType[] = [];
  messages.forEach((event) => {
    let msgBody: MatrixAnnouncementContent | null;
    try {
      msgBody = JSON.parse(event.getContent().body);
    } catch {
      msgBody = null;
    }

    if (msgBody && lcFirst(msgBody.type) === NotificationReasons.Announcement) {
      announcements.push({
        date: event.getDate() ?? new Date(),
        id: event.getId() ?? '',
        isUnread: false,
        title: msgBody.title ?? '',
        message: msgBody.content ?? '',
        matrixEvent: event,
      });
    }
  });
  return announcements;
};
